<template>
  <div class="max-w-4xl mx-auto mb-10">
    <div
      class="
        flex
        items-center
        justify-center
        col-span-5
        print-display
        space-x-2
      "
    >
      <button
        @click="previousMonth"
        :disabled="
          new Date(
            forecastDate.getFullYear(),
            forecastDate.getMonth(),
            1
          ).toString() ===
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
          ).toString()
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          :class="
            new Date(
              forecastDate.getFullYear(),
              forecastDate.getMonth(),
              1
            ).toString() ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              1
            ).toString()
              ? 'text-gray-300'
              : 'text-secondary'
          "
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 9rem"
        class="
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="'MM.yyyy'"
        :minimumView="'month'"
        :maximumView="'year'"
        :initialView="'year'"
        :lowerLimit="startForcastDate"
        :locale="lan"
        v-model="forecastDate"
      ></datepicker>
      <button @click="nextMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
      <!-- <button @click="print">print</button> -->
    </div>
    <h1 class="text-xl font-semibold mt-10">
      <span class="text-gray-600"> {{ $t("Balance until today") }} : </span>
      <span
        v-if="forecastList.total"
        :class="
          Math.sign(forecastList.total) == true
            ? 'text-green-700'
            : 'text-red-500'
        "
      >
        {{
          forecastList.total.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "EUR",
          })
        }}
      </span>
    </h1>
    <h1 class="text-xl font-semibold mt-10">
      <span class="text-gray-600">
        {{ $t("Forecast by the end") }}
        {{
          convertDate(
            `${forecastDate.getFullYear()}-${(forecastDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}`
          )
        }}
      </span>
    </h1>
    <div
      class="mt-10"
      v-for="(item, index) in forecastList.months"
      :key="index"
    >
      <div
        @click="goToMovement(item)"
        v-if="Math.sign(item.total_amount) == true"
        class="ldir cursor-pointer"
      >
        <div>
          <div class="flex justify-between font-semibold text-green-700">
            <span class="">{{ convertDate(item.month) }}</span>
            <span>{{
              item.total_amount.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "EUR",
              })
            }}</span>
          </div>
          <KProgress
            :percent="
              calcPercentage(forecastList.maxPosNumber, item.total_amount)
            "
            :color="'green'"
            active
            :show-text="false"
            :line-height="12"
          ></KProgress>
        </div>
      </div>
      <div
        @click="goToMovement(item)"
        v-else-if="item.total_amount == 0"
        class="ldir cursor-pointer"
      >
        <div>
          <div class="flex justify-between font-semibold text-gray-700">
            <span class="">{{ convertDate(item.month) }}</span>
            <span>{{
              item.total_amount.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "EUR",
              })
            }}</span>
          </div>
          <KProgress
            :percent="
              calcPercentage(forecastList.maxPosNumber, item.total_amount)
            "
            :color="'gray'"
            active
            :show-text="false"
            :line-height="12"
          ></KProgress>
        </div>
      </div>
      <div @click="goToMovement(item)" v-else class="mt-5 cursor-pointer">
        <div class="flex w-full justify-between my-1">
          <span class="text-red-500 font-semibold">
            {{ convertDate(item.month) }}
          </span>
          <span class="text-red-500 font-semibold">
            {{
              item.total_amount.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "EUR",
              })
            }}
          </span>
        </div>
        <div dir="rtl">
          <div class="rdir">
            <KProgress
              :percent="
                calcPercentage(
                  forecastList.maxNegNumber,
                  Math.abs(item.total_amount)
                )
              "
              :color="'red'"
              active
              :show-text="false"
              :line-height="12"
            ></KProgress>
          </div>
        </div>
      </div>
    </div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :loader="'dots'"
      :color="'#963d97'"
    />
  </div>
</template>
<script>
import KProgress from "k-progress-v3";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { format } from "date-fns";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { KProgress, Datepicker, Loading },
  data() {
    let currentDate =
      new Date().getMonth() == 11
        ? new Date(new Date().getFullYear() + 1, 0, 1)
        : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    return {
      forecastDate: currentDate,
      startForcastDate:
        new Date().getMonth() == 11
          ? new Date(new Date().getFullYear() + 1, 0, 1)
          : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      lan: this.$i18n.locale == "de" ? de : it,
      isLoading: false,
    };
  },
  watch: {
    forecastList() {
      // console.log(this.forecastList);
    },
    forecastDate: function () {
      localStorage.setItem("forecastTime", this.forecastDate);
      this.$store.commit("setMainDate", this.forecastDate);
      this.getForecastData();
    },
  },
  computed: {
    forecastList() {
      return this.$store.state.statistic.forecastList || [];
    },
  },
  methods: {
    getForecastData() {
      this.isLoading = true;
      this.$store
        .dispatch("statistic/getForecastData", {
          start: this.startDate(),
          end: this.endDate(),
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    startDate() {
      return `${this.startForcastDate.getFullYear()}-${(
        this.startForcastDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-01`;
    },
    endDate() {
      return `${this.forecastDate.getFullYear()}-${(
        this.forecastDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${this.endMonthDay()}`;
    },
    endMonthDay() {
      const endDate = new Date(
        this.forecastDate.getFullYear(),
        this.forecastDate.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    convertDate(date) {
      return format(new Date(`${date}-01`), "MMMM , yyyy", {
        locale: this.$i18n.locale == "de" ? de : it,
      });
    },
    calcPercentage(max, num) {
      return (num * 100) / max;
    },
    goToMovement(item) {
      // console.log(item);
      // this.$router.push('/dashboard', {date:'hi'})
      this.$router.push({ path: "/dashboard", query: { date: item.month } });
    },
    nextMonth() {
      if (this.forecastDate.getMonth() == 11) {
        this.forecastDate = new Date(this.forecastDate.getFullYear() + 1, 0, 1);
      } else {
        this.forecastDate = new Date(
          this.forecastDate.getFullYear(),
          this.forecastDate.getMonth() + 1,
          1
        );
      }
    },
    previousMonth() {
      const date = new Date();
      if (
        new Date(
          this.forecastDate.getFullYear(),
          this.forecastDate.getMonth(),
          1
        ).toString() ===
        new Date(date.getFullYear(), date.getMonth() + 1, 1).toString()
      ) {
        console.log("nemishe");
      }
      if (this.forecastDate.getMonth() == 0) {
        this.forecastDate = new Date(
          this.forecastDate.getFullYear() - 1,
          11,
          1
        );
      } else {
        this.forecastDate = new Date(
          this.forecastDate.getFullYear(),
          this.forecastDate.getMonth() - 1,
          1
        );
      }
    },
  },
  created() {
    if (localStorage.getItem("forecastTime")) {
      this.forecastDate = new Date(localStorage.getItem("forecastTime"));
    }
  },
  mounted() {
    this.getForecastData();
    this.$store.commit("setMainDate", new Date());
  },
};
</script>
<style>
.ldir .k-progress-outer {
  padding-right: 0 !important;
}
.rdir .k-progress-outer {
  padding-right: 0px;
  margin: -7px;
}
.k-progress {
  width: 100%;
}
</style>
